import { render, staticRenderFns } from "./Treatment.vue?vue&type=template&id=5c91c412&scoped=true&"
import script from "./Treatment.vue?vue&type=script&lang=js&"
export * from "./Treatment.vue?vue&type=script&lang=js&"
import style0 from "./Treatment.vue?vue&type=style&index=0&id=5c91c412&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c91c412",
  null
  
)

export default component.exports